<template>
  <div class="progress-wrap btn-right-side" @click="scrollToTop">
    <svg height="50" width="50" style="transform: rotate(-90deg)">
      <circle cx="25" cy="25" r="20" stroke="black" stroke-width="2" fill="transparent" style="stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;" :style="svgObject"/>
    </svg>
    <svg height="50" width="50" class="abs">
      <circle cx="25" cy="25" r="20" stroke="#ccc" stroke-width="2" fill="transparent" />
    </svg>
    <div class="abs">
      <div class="row h-100 align-items-center justify-content-center">
        <IconArrowUp color="black" size="size20" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconArrowUp: defineAsyncComponent(() => import('./icons/IconArrowUp.vue'))
  },
  name: 'BackToTop',
  props: ['percentage'],
  data () {
    return {
      svgObject: {
        strokeDashoffset: ''
      },
      styleObject: {
        backgroundImage: '',
        opacity: 1
      },
      styleObject_left: {
        backgroundImage: '',
        opacity: 1
      }
    }
  },
  watch: {
    percentage () {
      this.setOffset()
    }

  },
  methods: {
    setOffset () {
      // 181.919 = full
      // 307.919 = start
      const amount = (307.919) - ((126 * this.percentage))
      this.svgObject.strokeDashoffset = amount
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
  .progress-wrap.btn-right-side {
    right: 10px;
  }
  .progress-wrap {
    bottom: calc(80px + env(safe-area-inset-bottom));
    height: 50px;
    width: 50px;
    position: fixed;
    display: block;
    cursor: pointer;
    z-index: 4;
    opacity: 1;
  }
  .abs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  @media (min-width: 992px) {
    .progress-wrap {
      bottom: 50px;
      height: 50px;
      width: 50px;
      position: fixed;
      display: block;
      cursor: pointer;
      z-index: 4;
      opacity: 1;
    }
    .progress-wrap.btn-right-side {
      right: 50px;
    }
  }
</style>
